import { isEmailValid, onLoad } from './libs/helpers';
import { Popup } from './libs/popup';
import { getFirebaseAuth } from './libs/firebase-auth';
import { onAuthStateChanged } from 'firebase/auth';
import stackedLogoDm from '/website/img/assets/parlay-nation-stacked-logo-dm_275x218.webp';
import stackedLogo from '/website/img/assets/parlay-nation-stacked-logo_275x218.webp';

const downloadPDFPopupId = 'download-pdf-popup';
let downloadPDFPopup: Popup,
  downloadPDFPopupValidationEl,
  downloadPDFPopupInputEl,
  currentPDFLink = '',
  currentDownloadButtonIndex = 0;

onLoad(() => {
  createEmailPopup();
});

onAuthStateChanged(getFirebaseAuth(), user => {
  updateDownloadLinks(user);
});

function createEmailPopup() {
  const html = `
    <picture>
      <source media="(prefers-color-scheme: dark)" srcset="${stackedLogoDm}" width="275" height="218">
      <img src="${stackedLogo}" alt="Logo" width="275" height="218" loading="lazy">
    </picture>
    <h4 style="margin: 0 0 0.8rem;">Enter email to receive PDF</h4>
    <input type="email" placeholder="Email"/>
    <button type="button" onclick="submitEmail()">
      Send PDF
    </button>
    <p class="validation" style="display: none;">
      <i class="fa fa-exclamation-triangle"></i>
      Please enter a valid email
    </p>
    <p class="additional-info">
      To download PDFs easier - <a href="javascript:;" onclick="authToggle()">Sign up</a> and save as many as you want with just a single click! </br></br> Do not worry, you will rarely get emails from me. I'd rather create more Craps stuff.
    </p>
  `;
  downloadPDFPopup = new Popup(downloadPDFPopupId, html);
  downloadPDFPopupValidationEl = downloadPDFPopup.element.querySelector('.validation');
  downloadPDFPopupInputEl = downloadPDFPopup.element.querySelector('input');
  downloadPDFPopupInputEl.addEventListener('keyup', e => {
    if (e.keyCode === 13) {
      // keyCode of Enter is 13
      window['submitEmail']();
    }
  });
}

// Find all download buttons
function getDownloadLinks() {
  const downloadButtonSelector = '.w3-btn.w3-right.pdf-dwnld-btn'; // green buttons
  const downloadButtonSelector2 = '.w3-btn.download-pdf'; // red buttons
  return [
    ...Array.from(document.querySelectorAll(downloadButtonSelector)),
    ...Array.from(document.querySelectorAll(downloadButtonSelector2)),
  ];
}

// Update all download buttons to be a direct download or through email
function updateDownloadLinks(isSignedIn) {
  getDownloadLinks().forEach((el, index) => {
    if (isSignedIn) {
      // make download instant
      const pdfLink = el.getAttribute('data-href');
      if (!pdfLink) return;
      el.setAttribute('href', pdfLink);
      el.setAttribute('target', '_blank');
      el.removeAttribute('onclick');
    } else {
      // make download through email
      const pdfLink = el.getAttribute('href');
      el.setAttribute('href', 'javascript:;');
      el.setAttribute('data-href', pdfLink);
      el.removeAttribute('target');
      el.setAttribute('onclick', `openDownloadPDFPopup('${pdfLink}', ${index})`);
    }
  });
}

// Show/hide email popup
window['openDownloadPDFPopup'] = (pdfLink, downloadButtonIndex) => {
  downloadPDFPopup.open();
  currentPDFLink = pdfLink || ''; // save pdfLink into a data-attribute
  currentDownloadButtonIndex = downloadButtonIndex || 0; // save downloadButtonIndex into a data-attribute
};

window['submitEmail'] = () => {
  const email = downloadPDFPopupInputEl.value.trim();
  if (isEmailValid(email)) {
    downloadPDFPopupValidationEl.style.display = 'none';
  } else {
    downloadPDFPopupValidationEl.style.display = 'block';
    return;
  }
  sendEmailWithPDF(email, currentPDFLink, currentDownloadButtonIndex);
  downloadPDFPopup.close();
};

// Send email request to Firebase Cloud Function
async function sendEmailWithPDF(to, pdfLink, downloadButtonIndex) {
  if (!to || !pdfLink) {
    return;
  }
  const buttonElement = getDownloadLinks()[downloadButtonIndex];
  updateDownloadButtonState(buttonElement, 'in-progress');
  const response = await fetch('/api/sendEmailWithPDF', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ to, pdfLink }),
  });
  const json = await response.json();
  updateDownloadButtonState(buttonElement, 'emailed');
  return json;
}

// Update button to "Sending..." and "✅ PDF was emailed"
function updateDownloadButtonState(buttonElement, state) {
  switch (state) {
    case 'in-progress': {
      buttonElement.innerHTML = 'Sending <i class="fa fa-spin fa-circle-o-notch"></i>';
      buttonElement.style.pointerEvents = 'none'; // to avoid multiple clicks
      buttonElement.style.cursor = 'default';
      buttonElement.style.filter = 'brightness(150%)'; // to make look like disabled
      break;
    }
    case 'emailed': {
      buttonElement.style.pointerEvents = 'auto';
      buttonElement.style.cursor = 'pointer';
      buttonElement.style.filter = 'brightness(100%)';
      buttonElement.innerHTML = `✅ PDF was emailed`;
      break;
    }
    case 'error': {
      buttonElement.style.pointerEvents = 'auto';
      buttonElement.style.cursor = 'pointer';
      buttonElement.style.filter = 'brightness(100%)';
      buttonElement.innerHTML = `❌ Error`;
      break;
    }
  }
}
