// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDXn-ucdHYdahS9pr8A03QE07EwnUrhyrk",
  authDomain: "parlay-nation.firebaseapp.com",
  projectId: "parlay-nation",
  storageBucket: "parlay-nation.appspot.com",
  messagingSenderId: "27662081004",
  appId: "1:27662081004:web:96200c7a70bd00a4b47ec9",
  measurementId: "G-JF2J481EFY"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
analytics.toString ();