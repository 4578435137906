export class Popup {
  element;

  constructor(id, innerHTML) {
    this.element = document.createElement('div');
    this.element.id = id;
    this.element.classList.add('popup');
    this.element.style.display = 'none';
    this.element.innerHTML = `
    <div class="popup__shadow"></div>
    <div class="popup__inner">
      <a class="popup__close" href="javascript:;" type="button">
        <i class="fa fa-times"></i>
      </a>
      ${innerHTML}
    </div>`;
    document.body.appendChild(this.element);
    this.element.querySelector('.popup__shadow').onclick = () => this.close();
    this.element.querySelector('.popup__close').onclick = () => this.close();
  }

  open() {
    this.element.style.display = 'flex';
  }

  close() {
    this.element.style.display = 'none';
  }
}
